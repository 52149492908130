import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import * as actions from "../redux/actions"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import Img from "gatsby-image"
import Styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import Button from "../components/ButtonLink"
import Footer from "../components/Footer"

import { BallTriangle as Loading } from "../components/Common/loaders"
import { Form, EyeHolder } from "../components/Form/StyledParts"
import { Eye, EyeSlash } from "../components/Common/fontawesome"
import PhoneInput from "../components/Form/phoneInput"
import { Danger as Err } from "../components/Form/Alerts"

const BgImage = Styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: ${({ height }) => (height ? height : "auto")};

  & > img {
    object-fit: ${({ fit }) =>
      fit ? `${fit} !important` : "cover !important"};
    object-position: ${({ position }) =>
      position ? `${position} !important` : "50% 50% !important"};
    font-family: ${({ fit }) =>
      fit ? `object-fit: ${fit} !important` : `object-fit: cover !important`};
    object-position: ${({ position }) =>
      position ? `${position} !important` : "50% 50% !important"};
  }
`
const UL = Styled.ul`
    font-size: 18px;
`
const FinePrint = Styled.span`
    font-size: 14px;
`

const generateYears = () => {
  const currYear = new Date().getFullYear() - 1
  const years = Array.from(new Array(100), (_, idx) => currYear - idx)
  return years
}
const generateNums = x => {
  const start = 1
  const nums = Array.from(new Array(x), (_, idx) => start + idx)
  return nums
}
const clean = s => s.trim()
const capitalize = s => s.replace(/^./, c => c.toUpperCase())
const proper = s => clean(capitalize(s))

const IndexPage = ({ authenticated, authError, loading, Register, data }) => {
  const [days] = useState(generateNums(31))
  const [months] = useState(generateNums(12))
  const [years] = useState(generateYears())
  const [isMasked, setIsMasked] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const [registerError, setRegisterError] = useState("")
  const { register, handleSubmit, errors, setValue } = useForm()

  useEffect(() => {
    if (authenticated) {
      navigate("/inbetween")
    }
  }, [])
  useEffect(() => {
    clearForm()
  }, [])
  const onSubmit = async data => {
    try {
      setIsDisabled(true)
      const {
        fname,
        lname,
        email,
        password,
        phone1,
        phone2,
        phone3,
        day,
        month,
        year,
      } = data
      const name = `${proper(fname)} ${proper(lname)}`
      const phone = `${phone1}${phone2}${phone3}`
      const birthday = `${day}/${month}/${year}`
      Register(name, email, password, phone, birthday)
    } catch (error) {
      setIsDisabled(false)
      console.log(`CatchError: ${error}`)
      setRegisterError("Error registering")
    }
  }
  const clearForm = () => {
    setValue("fname", "")
    setValue("lname", "")
    setValue("email", "")
    setValue("password", "")
    setValue("phone1", "")
    setValue("phone2", "")
    setValue("phone3", "")
    setValue("day", "")
    setValue("month", "")
    setValue("year", "")
  }
  const renderButton = () => {
    if (loading) {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <Loading color={`#ff0000`} size={`75`} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="col-12 text-center mt-2">
            <Button
              id="register"
              disabled={isDisabled}
              onClick={handleSubmit(onSubmit)}
            >
              I Agree - Register Now
            </Button>
          </div>
        </div>
      )
    }
  }
  const renderAlert = () => {
    if (authError) {
      return (
        <div className="row">
          <div className="col-12 text-center mt-3">
            <Err>{authError}</Err>
          </div>
        </div>
      )
    } else if (registerError) {
      return (
        <div className="row">
          <div className="col-12 text-center mt-3">
            <Err>{registerError}</Err>
          </div>
        </div>
      )
    }
  }
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  const promoLong = data.promoLong.edges[0].node.childImageSharp.fluid
  return (
    <Layout>
      <SEO title="Dixie Bracket | Welcome" />
      <Header
        fluid={headerImg}
        height={350}
        mobile={300}
        alt={"Dixie Bracket - Welcome"}
      />
      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-12 text-center">
            <h1>Welcome to Dixie Bracket</h1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center">
            <iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fdixiebracket.com%2F&width=182&layout=button&action=recommend&size=large&share=true&height=65&appId"
              width="182"
              height="65"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allow="encrypted-media"
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-12 text-center">
            <p className="mb-2">Already Registered?</p>
            <Button to="/login">Login</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <BgImage
              fluid={promoLong}
              alt="Brandon Hunt - March Craziness"
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
        </div>
        <div className="row py-4">
          <div className="col-12 text-center">
            <h2>Free To Play - Register Now</h2>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-6 mr-auto ml-auto">
            <div className="container-fluid">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="container-fluid">
                  {/* ----------------------------------------------------------------- */}
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        name="fname"
                        placeholder="First Name"
                        ref={register({
                          required: "Required",
                          maxLength: 80,
                        })}
                      />
                      {errors.fname && <p>{errors.fname.message}</p>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        name="lname"
                        placeholder="Last Name"
                        ref={register({
                          required: "Required",
                          maxLength: 50,
                        })}
                      />
                      {errors.lname && <p>{errors.lname.message}</p>}
                    </div>
                  </div>
                  {/* ----------------------------------------------------------------- */}
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        ref={register({
                          required: "Email Required",
                          pattern: {
                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && <p>{errors.email.message}</p>}
                    </div>
                  </div>
                  {/* ----------------------------------------------------------------- */}
                  <div className="row">
                    <div className="col-12">
                      <input
                        type={isMasked ? "password" : "text"}
                        placeholder="Password"
                        name="password"
                        ref={register({
                          required: "Password is required",
                          maxLength: 100,
                        })}
                      />
                      {isMasked ? (
                        <EyeHolder>
                          <Eye
                            width={24}
                            onClick={() => setIsMasked(!isMasked)}
                          />
                        </EyeHolder>
                      ) : (
                        <EyeHolder>
                          <EyeSlash
                            width={24}
                            onClick={() => setIsMasked(!isMasked)}
                          />
                        </EyeHolder>
                      )}
                      {errors.password && <p>{errors.password.message}</p>}
                    </div>
                  </div>
                  {/* ----------------------------------------------------------------- */}
                  <PhoneInput
                    label={true}
                    errors={errors}
                    ref1={register({
                      required: "Required",
                      min: 3,
                      maxLength: 3,
                    })}
                    ref2={register({
                      required: "Required",
                      min: 3,
                      maxLength: 3,
                    })}
                    ref3={register({
                      required: "Required",
                      min: 4,
                      maxLength: 4,
                    })}
                    next={"month"}
                  />
                  {/* ----------------------------------------------------------------- */}
                  <div className="row">
                    <div className="col-12 text-center">
                      <label htmlFor="carrier">Birthday</label>
                    </div>
                    <div className="col-4">
                      <select
                        id="month"
                        name="month"
                        ref={register({
                          required: "Required",
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Month
                        </option>
                        {months.map(month => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                      {errors.month && <p>{errors.month.message}</p>}
                    </div>
                    <div className="col-4">
                      <select
                        id="day"
                        name="day"
                        ref={register({
                          required: "Required",
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled className="disabled">
                          Day
                        </option>
                        {days.map(day => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                      {errors.day && <p>{errors.day.message}</p>}
                    </div>

                    <div className="col-4">
                      <select
                        id="year"
                        name="year"
                        ref={register({
                          required: "Required",
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Year
                        </option>
                        {years.map(year => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {errors.year && <p>{errors.year.message}</p>}
                    </div>
                  </div>
                  {/* ----------------------------------------------------------------- */}
                  <div className="row mt-2">
                    <hr />
                    <div className="col-12 text-center">
                      <h1>Participation Rules</h1>
                      <UL>
                        <li>Must be 18 or older to play.</li>
                        <li>Only 1 bracket per person.</li>
                        <li>Win $100k for a perfect bracket!</li>
                        <li>
                          Top 100 scores will get a chance to roll dice to win
                          $100k!
                        </li>
                        <li>Other prizes will be announce later.</li>
                      </UL>
                      <FinePrint>
                        <i>
                          *In the case of multiple perfect brackets or other
                          winners of the $100k prize, all winners will split the
                          $100k evenly. All other oddsonpromotions rules apply.
                        </i>
                      </FinePrint>
                    </div>
                  </div>
                  {/* ----------------------------------------------------------------- */}
                  {renderButton()}
                  {renderAlert()}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query indexQuery {
    header: allFile(filter: { name: { eq: "index_header_1202x675" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1202) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    promoLong: allFile(filter: { name: { eq: "promo_970x250" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 970) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

const MapStateToProps = state => {
  return {
    loading: state.auth.loading,
    authenticated: state.auth.authenticated,
    authError: state.auth.authError,
    bracket: state.auth.bracket,
    user: state.auth.user,
    bracketSuccess: state.auth.bracketSuccess,
    csvSuccess: state.auth.csvSuccess,
    csvError: state.auth.csvError,
    updateSuccess: state.auth.updateSuccess,
    updateError: state.auth.updateError,
  }
}

export default connect(MapStateToProps, actions)(IndexPage)
