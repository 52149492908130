import React from "react"
import Styled from "styled-components"

import ExternalLink from "../ExternalLink"
import { Phone } from "../../components/Common/fontawesome"

const Footer = Styled.footer`
    
    font-size: 1rem;
`
const BlackBg = Styled.div`
    color: ${({ theme }) => theme.White};
    background-color: ${({ theme }) => theme.Black};
`
const FooterTop = Styled.div`
    display: flex;
    justify-content: center;
    ul {
        padding-left: 0px;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            border-bottom: ${({ theme }) => `1px solid ${theme.White}`};
            padding: 10px;
            a {
				cursor: pointer;
                margin-left: 10px;
                color: ${({ theme }) => theme.White};
                &:hover {
                  text-decoration: none;
                  color: ${({ theme }) => theme.Red};
                }
                svg {
                    width: 1.5rem;
                    color: ${({ theme }) => theme.White};
                    &:hover {
                        text-decoration: none;
                        color: ${({ theme }) => theme.Red};
                    }
                }
            }
        }
    } 
`
const FooterBottom = Styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    color: rgba(255,255,255,0.5);
`

const MyFooter = () => (
  <Footer>
    <div className="container-fluid">
      <BlackBg className="row">
        <FooterTop className="col-12">
          <ul>
            <li>
              <ExternalLink href={"tel:435-767-9100"}>
                <Phone />
                <span style={{ marginLeft: 10 }}>Call Brandon Hunt</span>
              </ExternalLink>
            </li>
          </ul>
        </FooterTop>
      </BlackBg>

      <BlackBg className="row">
        <div className="col-lg-12">
          <FooterBottom>
            <ul>
              <li>
                <span>&copy; {new Date().getFullYear()} Dixie Bracket</span>
              </li>
            </ul>
          </FooterBottom>
        </div>
      </BlackBg>
    </div>
  </Footer>
)

export default MyFooter
