import React from "react"
import { Link } from "gatsby"
import Styled from "styled-components"

const BtnLink = Styled(Link)`
    letter-spacing: 3px;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    background-color: ${({ theme }) => theme.Red};
    color: ${({ theme }) => theme.Black};
    white-space: normal;
    border: 0;
    outline: 0;
    display: inline-block;
    line-height: 25px;
    padding: 5px 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    font-size: 15px;
    font-weight: 600;
    border-radius: 0px;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 150ms ease;
    &:hover {
        text-decoration: none;
        background-color: ${({ theme }) => theme.Black};
        color: ${({ theme }) => theme.White};
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }
`
const Btn = Styled.button`
letter-spacing: 3px;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    background-color: ${({ theme }) => theme.Red};
    color: ${({ theme }) => theme.Black};
    white-space: normal;
    border: 0;
    outline: 0;
    display: inline-block;
    line-height: 25px;
    padding: 5px 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    font-size: 15px;
    font-weight: 600;
    border-radius: 0px;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 150ms ease;
    &:hover {
        text-decoration: none;
        background-color: ${({ theme }) => theme.Black};
        color: ${({ theme }) => theme.White};
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }
`

const Button = ({ children, to, onClick }) => {
  if (onClick) {
    return <Btn onClick={onClick}>{children}</Btn>
  } else if (to) {
    return <BtnLink to={to}>{children}</BtnLink>
  }
  return null
}

export default Button
